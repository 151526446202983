import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"

const Gallery = () => {
  return (
    <Layout>
        <div className="content-box">
            <Image/>
        </div>
    </Layout>
  )
 }

 export default Gallery

import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Gallery from ".././components/gallery"

const Image =  props  => (
<StaticQuery 
    query={graphql`
    query {
      images: allFile(filter: {relativeDirectory: {eq: ""}, ext: {eq: ".jpg"}}, sort: {order: DESC, fields: birthTime}) {
        edges {
          node {
            id
            name
            relativePath
            relativeDirectory
            childImageSharp {
              fixed (width: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid {
                originalImg
              }
            }
          }
        }
      }
    }
    
    `}
    render={data => (
      <Gallery
        itemsPerRow={[1]} // This will be changed to `[2, 3]` later
        images={data.images.edges.map(({ node }) => ({
          ...node
        }))}
        />
    )}
  />
)

export default Image